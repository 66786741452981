import React from 'react'
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import Banner from '../components/Banner/Banner';
import CallUs from '../components/CallUs/CallUs';
import QuestionForm from '../components/Form/QuestionForm';

import Padres from '../img/parents.jpg';

const { Panel } = Collapse;

export default function ParentsInfo(props){
    return (
    <>
        <Banner bannerImg={Padres} section={'Información para Padres'}/>
        <div className="whiteBlock grayTextBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1 id="costosTitleSection" >¡Todas tus dudas se resolverán!</h1>
                   <h2 style={{fontWeight: '600px'}}>Estas son las preguntas más frecuentes</h2>
                </div>
                <div>
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="site-collapse-custom-collapse"
                    style={{borderRadius:'5px', backgroundColor: '#FFF', paddingBottom: '10px'}}>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Cómo es el método de <strong>Inscripción?</strong></h3>} className="site-collapse-custom-panel">
                            <p>El método de inscripción es muy sencillo, únicamente deberás ponerte en <strong>contácto con nuestro departamento de control escolar </strong> 
                             a través del Correo Electrónico: <strong>servicios.escolares@igfe.mx</strong>, ellos te guiaran durante todo el proceso.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Son <strong>estudios validos ante la SEP?</strong></h3>} className="site-collapse-custom-panel">
                            <p>Contamos con el <strong>Registro de Validez Oficial de Estudios</strong> de la Secretaria de Educación Publica.
                            Nuestro número de RVOE: 1229/2021. ¡Por lo que los estudios serán validos de <strong>manera oficial en todo México!</strong>.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Como se realiza el <strong>pago del curso?</strong></h3>} className="site-collapse-custom-panel">
                            <p>El pago se realiza por medio de <strong>transferencia electrónica</strong>, 
                                comunícate al departamento de <strong>control escolar</strong> para más información.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿En <strong>donde están ubicados?</strong></h3>} className="site-collapse-custom-panel">
                            <p>Aunque nuestros servicios son totalmente en línea, el IGFE cuenta con oficinas físicas 
                                ubicadas en: <strong>Calle Horacio #115 Col. Fátima C.P: 34060. Durango, Dgo., México</strong>.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Cuanto <strong>dura la Prepa en Línea</strong>?</h3>} className="site-collapse-custom-panel">
                            <p>Se tiene contemplado de manera oficial una <strong>duración de 2 años</strong>, siempre y cuando se aprueben de manera satisfactoria todas las materias.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Se pueden <strong>adelantar materias</strong>?</h3>} className="site-collapse-custom-panel">
                            <p>No, el plan de estudios de Prepa en Línea IGFE, está diseñado a manera de que 
                                se curse en <strong>2 años para que así puedas desarrollar las competencias de manera 
                                exitosa</strong>.</p>
                            </Panel>
                </Collapse>
                </div>
            </div>
        </div>
        <div className="block textBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1>¿Aún te quedan dudas?</h1>
                   <h2>¡Escríbelas y con gusto te responderemos!</h2>
               </div>
                 <QuestionForm />
           </div>
        </div>
        <CallUs backColor={'#f7b41f'} btnSecondColor={'#206cb4'} btnTextColor={'#FFF'}/>
    </>
    );
}