import CookieConsent from "react-cookie-consent";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: 'GTM-TCVJNFK',
	dataLayer: {
		event: "cookies-aceptadas",
	}
}

function CookiesBanner() {

	if(localStorage.getItem("cookies-aceptadas")){
		return (
			TagManager.initialize(tagManagerArgs)
			);
	 } else{
		
		return(

<CookieConsent
buttonText="Aceptar y Continuar"
cookieName="IGFE"
acceptOnScroll={true}
acceptOnScrollPercentage={25}
expires={365}
overlay={true}
onAccept={(byScroll) => {

 localStorage.setItem("cookies-aceptadas", true);

 TagManager.initialize(tagManagerArgs)

 window.location.reload(true);
 
}}

buttonStyle={{
 textTransform: "uppercase",
 fontWeight: "900",
 color: "#5f5c5d",
 fontSize: "0.75rem",
 height: "40px",
 justifyContent: "center",
}}
>
 Nuestro sitio web hace uso de Cookies para mejorar los servicios que te brindamos. 
 <span style={{ fontSize: "10px" }}> Puedes conocer más acerca acerca de esto en nuestra 
 <a rel="noreferrer" href="https://drive.google.com/file/d/1bHc8kHiagNospOsh0EDqYbXEuAln7CFp/view?usp=sharing" target={"_blank"}> política de cookies</a>
   </span>.
 </CookieConsent>
)
};
};


export default CookiesBanner;