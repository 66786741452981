import React from 'react'
import { CaretUpOutlined } from '@ant-design/icons';
import { BackTop } from 'antd';
import { useParams } from 'react-router-dom';


import AppHero from '../components/Hero/AppHero';
import AppProposal from '../components/Proposal/Proposal';
import AppProcess from '../components/Process/AppProcess';
import About from '../components/About/AboutIGFE';
import Testimonials from '../components/Testimonials/Testimonials';
import Questions from '../components/Questions/Questions';
import CallUs from '../components/CallUs/CallUs';

import Letrero from '../img/instituto-global.jpg';

const Paragraph = <p>En el <strong>Instituto Global de Formación Y Educación</strong>, 
tenemos la convicción de que para la educación <strong>no 
existen fronteras</strong>, es por eso que desarrollamos una 
<strong> plataforma virtual</strong>, en donde a través de una modalidad 
<strong> 100% en línea</strong> podrás formarte profesionalmente <strong>estés donde 
estés</strong> y a la hora que te sea posible.</p>

const style = {
    height: 35,
    width: 35,
    lineHeight: '35px',
    borderRadius: 3,
    backgroundColor: '#206cb4',
    color: '#f7b41f',
    textAlign: 'center',
    fontSize: 15,
  };

export default function Home(){

    const { url } = useParams();

    return (
        <>
        <AppHero />
        <AppProposal />
        <AppProcess />
        <About idComponent={'Nosotros'} aboutImg={Letrero}
               aboutTitle={'IGFE'}
               aboutSubTitle={'Estas son las iniciales de un Instituto diseñado para ti'}
               aboutText={Paragraph}
               btnLink={'/about-us'}
               btnText={'Conocer Más'} />
        <Testimonials />
        <Questions />
        <CallUs colorText={'#FFF'} secondColor={'#F7B41F'} btnTextColor={'#5F5C5D'} />
        <BackTop visibilityHeight={5000}>
            <div style={style}><CaretUpOutlined /></div>
        </BackTop>
       </>
    );
}