import React, { useState } from 'react';
import { Link as Dom } from 'react-router-dom'
import { Anchor } from 'antd';
import Logo from '../../img/Logo-IGFE.svg';
import { Drawer, Button } from 'antd';
import { PhoneFilled, MenuOutlined } from '@ant-design/icons';

const { Link } = Anchor;

function Navbar() {

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

    return (
      <div className='container-fluid'>
       <div className='header'> 
        <div className="logo">
        <Dom to="/">
          <img src={Logo} width={'200rem'} height={'100%'} alt={"Instituto Global de Formación y Educación"}/>
          </Dom>
        </div>
        <div className='mobileHidden'>
      <Anchor targetOffset="6">
      <div style={{display: 'flex', flexDirection: 'row', margin: 'auto', width: '100%', paddingRight: '80px' }}>
          <Link href="/#Inicio" title="Inicio" />
          <Link href="/#Oferta" title="Oferta Educativa" />
          <Link href="/#Nosotros" title="¿Quiénes Somos?" />
          <Link href="/#Testimonios" title="Testimonios" />
          <Link href="/#FAQ" title="Preguntas Frecuentes" />
        </div>
          <Link href="/preparatoria">
           <Dom to='/contacto'>
            <Button type='primary' className='infoBtn'>
              <PhoneFilled className='Phone' />Solicitar Informes</Button>
           </Dom>
            </Link>
        </Anchor>
        </div>
        <div className='mobileVisible'>
        <Button type="primary" onClick={showDrawer}>
        <MenuOutlined />
      </Button>
      <Drawer title="Instituto Global de Formación y Educación" placement="right" 
              onClose={onClose} visible={visible} width={320}>
      <Anchor targetOffset="65" onClick={onClose}>
          <Link href="/" title="Inicio"/>
          <Link href="/oferta-educativa" title="Oferta Educativa" />
          <Link href="/about-us" title="¿Quiénes Somos?" />
          <Link href="/#Testimonios" title="Testimonios" />
          <Link href="/#FAQ" title="Preguntas Frecuentes" />
          <Dom to="/contacto" onClick={onClose}>
            <Button type='primary' className='infoBtn'>
              <PhoneFilled className='Phone' />Solicitar Informes</Button>
            </Dom>
        </Anchor>
      </Drawer>
        </div>
       </div>
      </div>
    );
  }
  
  export default Navbar;

 /* <Menu className="NavBtn" mode="horizontal" defaultSelectedKeys={['Inicio']}>
  <Menu.Item key="Inicio">Inicio</Menu.Item>
  <Menu.Item key="Educación">Oferta Educativa</Menu.Item>
  <Menu.Item key="Nosotros">¿Quiénes Somos?</Menu.Item>
  <Menu.Item key="Testimonios">Testimonios</Menu.Item>
  <Menu.Item key="FAQ">Preguntas Frecuentes</Menu.Item>
  <Menu.Item key="Blog">Blog</Menu.Item>
</Menu> */