import React from "react";
import { Link, useLocation } from "react-router-dom";
import {  Menu } from "antd";
import { HomeOutlined, UserOutlined,BookOutlined } from "@ant-design/icons";
// import logo from "../../../assets/images/png/asdd.png";

import "./MenuSider.less";

// const mountedStyle = { animation: "inAnimation 250ms ease-in" };
// const unmountedStyle = {
//   animation: "outAnimation 270ms ease-out",
//   animationFillMode: "forwards",
// };

export default function MenuSider(props) {
  let location = useLocation();
  return (
    <div className="menu-sider">
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item
          key="/"
          icon={<HomeOutlined />}
        >
          <Link to={"/admin/"}>
            <span className="nav-text">Home</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/users"
          icon={<UserOutlined />}
        >
          <Link to={"/admin/users"}>
            <span className="nav-text">usuarios</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="/blogs"
          icon={<BookOutlined />}
        >
          <Link to={"/admin/blog?page=1&limit=6"}>
            <span className="nav-text">Blog</span>
          </Link>
        </Menu.Item>
      </Menu>
      </div>
  );
}

// export default withRouter(MenuSider);
