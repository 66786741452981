import React, { useState } from "react";
import { Input, Button, Col, Row, Image, notification,Form } from "antd";
import {
  MailFilled,
  CompassFilled,
  PhoneFilled,
  CaretRightFilled,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterSquareFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import LogoBCO from "../../img/LogoBCO-IGFE.svg";
import WhatsAppBCO from "../../img/whatsappBCO.svg";
import LogoIcon from "../../img/icon-igfe.svg";
import { subscribeMailChimpApi } from "../../api/emails";


export default function AppFooter() {
  const [inputs, setInputs] = useState({
    email: "",
  });

  const changeForm = (e) => {
    if (e.target.name === "privacyPolicy") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const sendEmail = async (e) => {
    const emailVal = inputs.email;

    if (!emailVal) {
      notification["error"]({
        message: "Favor de ingresar una dirección de correo electrónico",
      });
    } else {
      const result = await subscribeMailChimpApi(inputs);
      if (result.code === 200) {
        notification["success"]({
          message: result.message,
        });
      } else {
        notification["error"]({
          message: result.message,
        });
        resetForm();
      }
    }
  };

  const resetForm = () => {
    setInputs({
      email: ""
    });
  };

  return (
    <>
      <div className="block footerBlock">
        <div className="container-fluid">
          <Form className="FooterTitleHolder"  onChange={changeForm} onFinish={sendEmail} >
            <h1>
              Suscríbete para recibir <br /> nuestras ultimas novedades
            </h1>
            <Input
              className="FooterCorreo"
              placeholder="Dirección de Correo Electrónico"
              type="email" name="email" value={inputs.email}
            />
            <Button type="primary" className="FooterSendEmail" htmlType="submit"> 
              Enviar
              <MailFilled className="MailIcon" />
            </Button>
            <div>
              <p style={{ fontSize: "0.7rem", color: "#fff" }}>
                Al enviar este formulario, estas aceptando nuestro{" "}
                <a
                  href="https://drive.google.com/file/d/1UiooxISlKgqNFhWjKw8GCVsZ7eer-F27/view?usp=sharing"
                  target={"_blank"} rel="noreferrer"
                >
                  aviso de privacidad
                </a>
                , asi como tambien recibir novedades y promociones.
              </p>
            </div>
          </Form>
          <div className="FooterContent">
            <Row gutter={[24, 16]}>
              <Col
                lg={{ span: 6 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 32 }}
              >
                <div>
                  <Link to="/">
                    <Image
                      className="FooterLogo"
                      width={280}
                      preview={false}
                      src={LogoBCO}
                    />
                  </Link>
                </div>
                <div className="FooterInfoIGFE">
                  <p>
                    Instituto <strong>100% en línea</strong>, con programas
                    educativos <strong>reconocidos ante la SEP</strong>. Con{" "}
                    <strong>Registro de Validez Oficial de Estudios</strong>{" "}
                    (RVOE) No. 1229/2021
                    <br />
                    <br />
                    #EducaciónSinFronteras
                  </p>
                </div>
              </Col>
              <Col
                lg={{ span: 6 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 32 }}
              >
                <h3>Contácto</h3>
                <div className="FooterInfoIGFE">
                  <div className="footerLinks">
                    <CompassFilled className="icon-footer" />
                    Horacio #115 Col. Fátima.
                    <br />
                    Durango, Dgo. México
                  </div>
                  <div className="footerLinks">
                    <MailFilled className="icon-footer" />
                    instituto.global@igfe.mx
                  </div>
                  <div className="footerLinks">
                    <PhoneFilled
                      className="icon-footer"
                      style={{ transform: "scaleX(-1)" }}
                    />
                    618-811-58-73
                  </div>
                  <div className="footerLinks">
                    <Image width={"1rem"} preview={false} src={WhatsAppBCO} />
                    <a
                      href="https://api.whatsapp.com/send?phone=526182304595"
                      target={"_blank"} rel="noreferrer"
                      style={{ marginLeft: "10px" }}
                    >
                      618-230-45-95
                    </a>
                  </div>
                </div>
              </Col>
              <Col
                lg={{ span: 6 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 32 }}
              >
                <h3>Navegación</h3>
                <div className="FooterInfoIGFE">
                  <div className="footerLinks">
                    <CaretRightFilled className="arrow-footer" />
                    <a href="/">Inicio</a>
                  </div>
                  <div className="footerLinks">
                    <CaretRightFilled className="arrow-footer" />
                    <a href="/oferta-educativa">Oferta Educativa</a>
                  </div>
                  <div className="footerLinks">
                    <CaretRightFilled className="arrow-footer" />
                    <a href="/about-us">Acerca de Nosotros</a>
                  </div>
                  <div className="footerLinks">
                    <CaretRightFilled className="arrow-footer" />
                    <a href="/#FAQ">Preguntas Frecuentes</a>
                  </div>
                  <div className="footerLinks">
                    <CaretRightFilled className="arrow-footer" />
                    <a href="/contacto">Contácto</a>
                  </div>
                </div>
              </Col>
              <Col
                lg={{ span: 6 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 32 }}
              >
                <h3 style={{ visibility: "hidden" }}>Navegación</h3>
                <div className="FooterInfoIGFE">
                  <div className="footerLinks" style={{ display: "none" }}>
                    <CaretRightFilled className="arrow-footer" />
                    <a href="/#blog">Blog</a>
                  </div>
                  <div className="footerLinks">
                    <CaretRightFilled className="arrow-footer" />
                    <a
                      href="https://drive.google.com/file/d/1UiooxISlKgqNFhWjKw8GCVsZ7eer-F27/view?usp=sharing"
                      target={"_blank"} rel="noreferrer"
                    >
                      Aviso de Privacidad
                    </a>
                  </div>
                  <div className="footerLinks">
                    <CaretRightFilled className="arrow-footer" />
                    <a
                    href="https://drive.google.com/file/d/1bHc8kHiagNospOsh0EDqYbXEuAln7CFp/view?usp=sharing"
                    target={"_blank"} rel="noreferrer">
                      Política de Cookies
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="FooterCopyright">
        <div className="CopyrightContainer">
          <div>
            <p>
              <strong>Fomento de Formación Global S.C.</strong> © 2022
            </p>
          </div>
          <div>
            <Image
              className="IGFEIconFooter"
              width={40}
              preview={false}
              src={LogoIcon}
            />
          </div>
          <div className="FooterSocialMedia">
            <a
              href="https://www.instagram.com/instituto.global"
              target={"_blank"} rel="noreferrer"
              style={{ color: "#5f5c5d" }}
            >
              <InstagramFilled className="SocialIcon" />
            </a>
            <a
              href="https://www.facebook.com/Instituto.IGFE"
              target={"_blank"} rel="noreferrer"
              style={{ color: "#5f5c5d" }}
            >
              <FacebookFilled className="SocialIcon" />
            </a>
            <a
              href="https://twitter.com/instituto_igfe"
              target={"_blank"} rel="noreferrer"
              style={{ color: "#5f5c5d" }}
            >
              <TwitterSquareFilled className="SocialIcon" />
            </a>
            <a
              href="https://www.linkedin.com/company/instituto-global-de-formacion-y-eduacion/"
              target={"_blank"} rel="noreferrer"
              style={{ color: "#5f5c5d" }}
            >
              <LinkedinFilled className="SocialIcon" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
