import React from "react";
import { Layout, Card } from "antd";
import { Navigate } from "react-router-dom";
import LoginForm from "../../../components/Admin/LoginForm";
import { getAccessTokenApi } from "../../../api/auth";

import "./SignIn.less";

export default function SignIn() {
  const { Content } = Layout;

  if (getAccessTokenApi()) {
    return  <Navigate to="/admin" replace/>
  }
  return (
    <Layout className="sign-in">
      <Content className="sign-in__content">
        <h1 className="sign-in__content-logo">
          Login
        </h1>

        <div className="sign-in__content-tabs">
          <div type="card">
            <Card title={<span>Inicio de sesión</span>} key="1">
              <LoginForm />
            </Card>
           
          </div>
        </div>
      </Content>
    </Layout>
  );
}