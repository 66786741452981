import React from 'react'
import { Row, Col, Image } from 'antd';
import { Link } from 'react-router-dom';

import E404 from '../img/404-error.png'

export  default function Error404(){
    return (
        <div className="whiteBlock textBlock" >
        <div className="container-fluid" style={{padding: '60px 20px'}}>
        <Row gutter={[24, 8]}>
            <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='left' style={{display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
            <div>
                    <h1 style={{ color: '#5f5c5d', fontSize: '4.5rem', lineHeight: '0.5', textTransform: 'capitalize'}}>Oops...</h1>
                    <h1 style={{ color: '#5f5c5d', fontSize: '1.1rem'}}>Parece que la página que buscas no esta disponible.</h1>
                    <p style={{ color: '#5f5c5d', fontSize: '1rem'}}>Puedes intentar las siguientes opciones:</p>
                        <ul style={{fontSize: '0.9rem', lineHeight: '2'}}>
                            <li style={{margin: '10px'}}>Revisa que tu <strong>conexión a Internet</strong> se encuentre activa.</li>
                            <li style={{margin: '10px'}}>Verifica que hayas <strong>escrito correctamente</strong> tu busqueda.</li>
                            <li style={{margin: '10px'}}>O simplemente regresa a la <Link to="/" style={{color: '#206cb4' }}><u><strong>página principal</strong></u></Link>.</li>
                        </ul>
             </div>
            </Col>
            <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle' style={{display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
                <div>
                    <Image width={290} src={E404} preview={false}/>
                </div>
            </Col>
        </Row>
        </div>
    </div>
    );
}