import React from 'react'
import { Row, Col, Button, Image, Card } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import WhatsApp from '../../img/whatsapp.svg';

function About(props) {

    return(
        <div id={props.idComponent} className="whiteBlock textBlock">
            <div className="container-fluid">
            <Row gutter={[24, 8]}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle'>
                    <div className='fotoInfo'>
                    <Image className='fachada' maxwidth={500}
                    src={props.aboutImg} alt={'Nuestras Oficinas'} preview={false}/>
                    <Card className='videoCall' bordered={false} style={{display: props.hideCard}}>
                        <h2>¡Agenda una Videollamada!</h2>
                        <p>Nuestro personal con gusto te brindará toda la información que necesites.</p>
                        <div className='WhatsAppBtnHolder'>
                         <img className='WhatsAppIcon' src={WhatsApp} width={'50px'} alt={'¡Mándanos un WhatsApp!'}/>
                        <a href='https://api.whatsapp.com/send?phone=526182304595' target={'_blank'}>
                            <Button type='primary' className='WhatsAppBtn'>618-230-45-95</Button>
                        </a>
                        </div>
                    </Card>
                    </div>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='right'>
                    <div className='IGFEcontent'>
                        <h1>{props.aboutTitle}</h1>
                        <h2>{props.aboutSubTitle}</h2>
                        <h2 id='curseTitle'>{props.aboutTitle2}</h2>
                        <div>{props.aboutText}</div>
                    </div>
                    <div className='btnHolder'>
                        <Link to={props.btnLink}>
                            <Button type='primary' className='aboutBtn' style={{backgroundColor: props.btnColor, color: props.btnTextColor}}>{props.btnText} <PlayCircleFilled className='Read' /></Button>
                        </Link>
                        </div>
                </Col>
            </Row>
            </div>
        </div>
    );
}

export default About;