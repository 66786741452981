import React from 'react'
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import Banner from '../components/Banner/Banner';
import CallUs from '../components/CallUs/CallUs';
import QuestionForm from '../components/Form/QuestionForm';

import Estudiante from '../img/students.jpg'

const { Panel } = Collapse;

export default function StudentsInfo(){
    return (
        <>
        <Banner bannerImg={Estudiante} section={'Información para Estudiantes'}/>
        <div className="whiteBlock grayTextBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1 id="costosTitleSection" >¡Las decisiones marcan el camino!</h1>
                   <h2 style={{fontWeight: '600px'}}>Estas son las preguntas más frecuentes</h2>
                </div>
                <div>
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="site-collapse-custom-collapse"
                    style={{borderRadius:'5px', backgroundColor: '#FFF', paddingBottom: '10px'}}>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Cómo me puedo <strong>Inscribir?</strong></h3>} className="site-collapse-custom-panel">
                            <p>El método de inscripción es muy sencillo, únicamente 
                            ponte en <strong>contácto con nuestro departamento de control escolar </strong> 
                             a través del Correo Electrónico: <strong>servicios.escolares@igfe.mx</strong>, ellos te guiaran durante todo el proceso.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Son <strong>estudios validos ante la SEP?</strong></h3>} className="site-collapse-custom-panel">
                            <p>Contamos con el <strong>Registro de Validez Oficial de Estudios</strong> de la Secretaria de Educación Publica.
                            Nuestro número de RVOE: 1229/2021. ¡Por lo que tus estudios serán validos de <strong>manera oficial en todo México!</strong>.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Puedo <strong>revalidar materias?</strong></h3>} className="site-collapse-custom-panel">
                            <p>Para más información acerca de la revalidación de materias puedes comunicarte directamente a nuestro 
                                departamento de Control Escolar: <strong>servicios.escolares@igfe.mx</strong>.
                            </p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿En donde realizo el <strong>pago del curso?</strong></h3>} className="site-collapse-custom-panel">
                            <p>El pago se realiza por medio de <strong>transferencia electrónica</strong>, 
                                comunícate al departamento de <strong>control escolar</strong> para más información.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Puedo <strong>adelantar materias</strong>?</h3>} className="site-collapse-custom-panel">
                            <p>No, el plan de estudios de Prepa en Línea IGFE, está diseñado a manera de que 
                                se curse en <strong>2 años para que así puedas desarrollar las competencias de manera 
                                exitosa</strong>.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Para estudiar en la prepa en línea en el IGFE, necesito tener un <strong>horario fijo de dedicación</strong>?</h3>} className="site-collapse-custom-panel">
                            <p><strong>Depende totalmente de ti</strong>, pues nuestra plataforma virtual está disponible las 24 horas del día, los 7 días de la semana, sin embargo, 
                                te recomendamos administrar tu tiempo de acuerdo con tus necesidades, considerando que las fechas de 
                                entrega de las actividades de aprendizaje están calendarizadas.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿En donde <strong>compro los libros</strong>?</h3>} className="site-collapse-custom-panel">
                            <p>Al ser el IGFE un Instituto 100% en línea, <strong>no es necesario que compres libros</strong>, ya que contamos 
                                con una <strong>Biblioteca Digital</strong> en donde tendrás acceso a todo el material que necesitarás para tus 
                                clases. Este acceso viene <strong>incluido con el pago de tu mensualidad</strong>.</p>
                            </Panel>
                </Collapse>
                </div>
            </div>
        </div>
        <div className="block textBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1>¿Aún te quedan dudas?</h1>
                   <h2>¡Escríbelas y con gusto te responderemos!</h2>
               </div>
                 <QuestionForm />
           </div>
        </div>
        <CallUs backColor={'#f7b41f'} btnSecondColor={'#206cb4'} btnTextColor={'#FFF'}/>
        </>
    );
}