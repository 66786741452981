import React, { useState } from "react";
import { Input, Checkbox, Button, Form,notification } from "antd";
import { sendEmailApi } from "../../api/emails";



export default function InfoForm(){
  const [inputs, setInputs] = useState({
    fullName: "",
    email: "",
    estado: "",
    cursoInteres: "",
    privacyPolicy: false
  });
  
  const changeForm = e => {
    if (e.target.name === "privacyPolicy") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      });
    }
  };

  const sendEmail = async e => {
    const emailVal = inputs.email;
    const fullnameval = inputs.fullName;
    const estadoval = inputs.estado;
    const cursoIteresval = inputs.cursoInteres;
    const privacyPolicyval = inputs.privacyPolicy;


    if (!emailVal || !fullnameval || !estadoval || !cursoIteresval) {
      notification["error"]({
        message: "Todos los campos son obligatorios"
      });
    } else {
      if (!privacyPolicyval){
        notification["error"]({
          message: "Favor de aceptar el acuerdo de privacidad"
        });
      }else{

        const result = await sendEmailApi(inputs);
        if (result.code===500) {
          notification["error"]({
            message: result.message
          });
        } else {
          notification["success"]({
            message: result.message
          });
          resetForm();
      }

    }
  }
}

  const resetForm = () => {
    setInputs({
      fullName: "",
      email: "",
      estado: "",
      cursoInteres: "",
      privacyPolicy: false
    }); 
  }


  return (
    <Form className="Forms" onChange={changeForm} onFinish={sendEmail} >
      <Input type="email" name="email" value={inputs.email} placeholder="Escribe tu Email" />
      <Input type="name" name="fullName" value= {inputs.fullName} placeholder="Tu Nombre Completo" />
      <Input name="estado" value= {inputs.estado} placeholder="Estado donde vives" />
      <Input name="cursoInteres" value= {inputs.cursoInteres} placeholder="Curso educativo en el que estas interesado" />
      <div className="btnCheck">
        <Checkbox name="privacyPolicy"  checked={inputs.privacyPolicy} style={{color: "#5f5c5d"}}>
          He leído y estoy de acuerdo con el <a href="https://drive.google.com/file/d/1UiooxISlKgqNFhWjKw8GCVsZ7eer-F27/view?usp=sharing" 
                            target={"_blank"} rel="noreferrer">Aviso de Privacidad.</a>
        </Checkbox>
        <Button className="btnBecas" type="primary" htmlType="submit">
          Enviar
        </Button>
      </div>
    </Form>
  );

}