import { Row, Col, Button, Image, Card } from 'antd';
import { ReadFilled } from '@ant-design/icons';

import EstudiantePrep from '../../img/estudiante-prep.jpg';

function Info() {
    return(
        <div id="Offers" className="whiteBlock textBlock">
            <div className="container-fluid">
            <Row gutter={[24, 8]}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 32 }} xs={{ span: 36 }}>
                    <div className='fotoInfo'>
                    <Image className='fachada' maxwidth={500}
                    src={EstudiantePrep} alt={'Nuestras Oficinas'} preview={false}/>
                    </div>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 32 }} xs={{ span: 36 }} align='right'>
                    <div className='IGFEcontent'>
                        <h2 id='curseTitle'>Preparatoria</h2>
                        <p>Inicia tu <strong>formación profesional</strong> con nuestra preparatoria. Con duración de <strong>2 años</strong>, 
                            estudia en una modalidad completamente flexible que te permitirá tomar clase <strong>a 
                            la hora que te sea posible, estés donde estés</strong>. <br/>
                            Si estas buscando desarrollar un excelente perfil, con <strong>certificado valido</strong> ante 
                            la SEP y sobre todo con <strong>maestros capacitados</strong>, ¡este es tu camino!.</p>
                    </div>
                    <div className='btnHolder'>
                            <Button type='primary' className='aboutBtn'>Conocer más <ReadFilled className='Read' /></Button>
                        </div>
                </Col>
            </Row>
            </div>
        </div>
    );
}

export default Info;