import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image } from 'antd';


import Precio from '../../img/Costos.png';

const servicios = ['Aula Virtual 24/7', 'Acompañamiento Académico',
                    'Libros Digitales', 'Material de Estudio',
                    'Biblioteca Digital', 'Certificado con Validez Oficial - SEP'];

const listServicios = servicios.map((servicio) => 
                        <li key={servicio} className="ServiciosCostos">{servicio}</li>
                        );

function Costos(props) {
    return (
        <div className='whiteBlock grayTextBlock' style={{ backgroundColor: '#EEEEEE' }}>
            <div className='container-fluid'>
                <div className='titleHolder'>
                    <h1 style={{ fontSize: '6rem' }}>Costos</h1>
                </div>
            <div style={{ backgroundColor: '#FFF', padding: '30px 30px', borderRadius: '20px', marginBottom: '30px'}}>
                <Row>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle'>
                        <div className="CostosTitles" style={{textAlign: 'left'}}>
                            <h1 style={{ padding: '0 0', margin: '0 0', lineHeight: '1'}}>Preparatoria</h1>
                            <h3 style={{ fontSize: '3rem', fontWeight: '900', color: '#5f5c5d', textTransform: 'uppercase'}}>Online a 2 Años</h3>
                        </div>
                        <ul style={{ textAlign: 'left', lineHeight: '2' }}>{listServicios}</ul>
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle'>
                        <div>
                        <Link to='/contacto'>
                        <Image className='fachada'
                            maxwidth={500}
                            src={Precio}
                            preview={false}
                        />
                        </Link>
                        </div>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    );
}

export default Costos;