import React, { useState, useEffect } from "react";
import { Spin, List, notification, Card } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import Pagination from "../../Pagination";
import { getPostsApi } from "../../../api/post";
import "moment/locale/es";

import "./PostsListWeb.less";

export default function PostsListWeb(props) {
  const { location, history } = props;
  const [posts, setPosts] = useState(null);
  const { page = 1, limit } = queryString.parse(location.search);

  useEffect(() => {
    getPostsApi(limit, page)
      .then((response) => {
        if (response?.code !== 200) {
          notification["warning"]({
            message: response.message,
          });
        } else {
          setPosts(response.posts);
        }
      })
      .catch(() => {
        notification["error"]({
          message: "Error del servidor.",
        });
      });
  }, [page,limit]);

  if (!posts) {
    return (
      <Spin tip="Cargando" style={{ width: "100%", padding: "200px 0" }} />
    );
  }

  return (
    <>
      <title>Blog</title>
      <div className="posts-list-web">
        <h1>Blog</h1>
        <List
          grid={{
            gutter: 30,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          dataSource={posts.docs}
          renderItem={(post) => <Post post={post} />}
          className = 'posts-list-web__list'
        />
        <Pagination posts={posts} location={location} history={history} />
      </div>
    </>
  );
}

function Post(props) {
  const { post } = props;
  const day = moment(post.date).format("DD");
  const month = moment(post.date).format("MMMM");

  return (
    <List.Item className="post">
      <Card
        style={{ width: 300 }}
        cover={
          <img
            alt="example"
            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          />
        }
      >
        <Card.Meta
          title={post.title}
          description={`${day} de ${month}`}
        />
        <Link to={`/blog/${post.url}`}>
          <List.Item.Meta title="Ver mas" />
        </Link>
      </Card>
    </List.Item>
  );
}

//<div className="post__date">
//   <span>{day}</span>
//   <span>{month}</span>
// </div>
// <Link to={`blog/${post.url}`}>
//  <List.Item.Meta title={post.title} />
//</Link>
