import React from 'react';

import './PhotoCard.less';


const PhotoCard = (props) => {
    return (
    <div className='card' style={{backgroundImage: `url(${props.card_image})`}}>
        <div className='cardContent'>
            <div className='cardTitle' id='card'>{props.card_title}</div>
            <div className='cardBody' id='card'>{props.card_text}</div>
        </div>
    </div>
    );
    }

export default PhotoCard;