import { Button, Carousel } from 'antd';
import { Link } from 'react-router-dom';
import { RightCircleFilled } from '@ant-design/icons';

const items = [
      {
            key: '1',
            title: 'Tu prepa online \n en solo 2 años',
            content: 'Con certificado ante la SEP',
      },
]

function AppHero() {
      return(
        <div id="Inicio" className='heroBlock'>
            <div className='videoContainer'>
            <video className='videoIGFE'
                  autoPlay
                  loop
                  muted
                  playsInline
                  src='https://igfe-website.s3.us-west-1.amazonaws.com/IGFE-video.mov'
                  maxwidth='100%'
                  maxheight='100%'
            />
            </div>
            <Carousel className='heroCarousel'>
                {items.map((item, index) => {
                      return (
                            <div className='container-fluid' key={index}>
                                  <div className='content'>
                                      <h1>{item.title}</h1>
                                      <p>{item.content}</p>
                                      <div className='btnHolder'>
                                      <Link to='/preparatoria'>
                                            <Button type='primary' className='heroBtn'>Conocer Más <RightCircleFilled className='more' /></Button>
                                      </Link>
                                      </div>
                                  </div>
                            </div>
                      );
                })}
          </Carousel>
        </div>
      );
}

export default AppHero;