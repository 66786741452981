import React from 'react';
import { Image, Carousel } from 'antd';
import Testimonial1 from '../../img/test1.jpg';
import Testimonial2 from '../../img/test2.jpg';
import Testimonial3 from '../../img/test3.jpg';
import Testimonial4 from '../../img/test4.jpg';


  const items = [
    {
        key: '1',
        icon: <Image className='testimonialImg' src={Testimonial1} alt='M.B.A. Alfonso Gastañaduy' width='120px' preview={false} />,
        title: <h5>“Educación de Calidad”</h5>,
        content: <p>“<strong>Instituto Global de Formación y Educación</strong>. Institución 
        llamada a liderar una educación de calidad."</p>,
        name: <h6>- <strong>M.B.A. Alfonso Gastañaduy</strong> - ESAN, Perú</h6>,
    },
    {
        key: '2',
        icon: <Image className='testimonialImg' src={Testimonial2} alt='Dr. Arturo Reveles' width='120px' preview={false} />,
        title: <h5>“Un buen nivel de calidad educativa”</h5>,
        content: <p>“Una <strong>Excelente alternativa</strong> para la formación de los jóvenes."</p>,
        name: <h6>- <strong>Dr. Arturo Reveles</strong> - UJED, México</h6>,
    },
    {
        key: '3',
        icon: <Image className='testimonialImg' src={Testimonial3} alt='M.A. Edla Wallander' width='120px' preview={false} />,
        title: <h5>“Grandes Beneficios para nuestra sociedad”</h5>,
        content: <p>“Lo decia Nelson Mandela; <strong>La educación</strong> es el arma más poderosa 
        para cambiar al mundo".</p>,
        name: <h6>- <strong>M.A. Edla Wallander</strong> - UJED, México</h6>,
    },
    {
        key: '4',
        icon: <Image className='testimonialImg' src={Testimonial4} alt='Dr. René Castro Berardi' width='120px' preview={false} />,
        title: <h5>“Educación Multicultural”</h5>,
        content: <p>“El <strong>Instituto</strong> va a permitir que varios conceptos 
        de <strong>carácter global</strong> puedan ser desarrollados a 
        través de la <strong>educación</strong>”</p>,
        name: <h6>- <strong>Dr. René Castro Berardi</strong> - UFPR, Brasil</h6>,
    },
]

class Testimonials extends React.Component {
    render() {
        return(
            <div id="Testimonios" className="block testimonialsBlock">
                <div className="container-fluid">
                    <div className="titleHolder">
                        <Carousel autoplay>
                        {items.map((item, index) => {
                               return(
                                <div className='testCarousel' key={index}>
                                    {item.icon}
                                    {item.title}
                                    {item.content}
                                    {item.name}
                                </div>
                               );
                           })}
                        </Carousel>
                    </div>
                </div>
            </div>
        );
    }
}

export default Testimonials;