import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutDefault from "../layouts/LayoutDefault";
import LayoutWeb from "../layouts/LayoutWeb";

//Admin pages
import AdminHome from "../pages/Admin/admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminBlogs from "../pages/Admin/blog";
import Users from '../pages/Admin/users'
import Error404 from "../pages/error404";

//Landing pages
import Home from '../pages/home';
import Offer from '../pages/offer';
import Preparatoria from '../pages/preparatoria';
import AboutUs from '../pages/about';
import ParentsInfo from '../pages/parentsInfo';
import StudentsInfo from '../pages/studentsInfo';
import Contacto from '../pages/contact';
import Blogs from '../pages/blogs'
import Blog from '../pages/blog'



const routesAdmin = [
  {
    path: "/admin",
    Layout: LayoutAdmin,
    component: AdminHome,
  },
  {
    path: "/admin/login",
    Layout: LayoutDefault,
    component: AdminSignIn,
  },
  {
    path: "/admin/users",
    Layout: LayoutAdmin,
    component: Users,
  },
  {
    path: "/admin/blog",
    Layout: LayoutAdmin,
    component: AdminBlogs,
  },
];

const routesWeb = [
  {
    path: "/",
    Layout: LayoutWeb,
    component: Home,
  },
  {
    path: "/oferta-educativa",
    Layout: LayoutWeb,
    component: Offer,
  },
  {
    path: "/preparatoria",
    Layout: LayoutWeb,
    component: Preparatoria,
  },
  {
    path: "/about-us",
    Layout: LayoutWeb,
    component: AboutUs,
  },
  {
    path: "/informacion-padres",
    Layout: LayoutWeb,
    component: ParentsInfo,
  },
  {
    path: "/informacion-estudiantes",
    Layout: LayoutWeb,
    component: StudentsInfo,
  },
  {
    path: "/contacto",
    Layout: LayoutWeb,
    component: Contacto,
  },
  {
    path: "/blog",
    Layout: LayoutWeb,
    component: Blog
  },
  {
    path: "/blog/:id",
    Layout: LayoutWeb,
    component: Blog
  },
  {
    path: "*",
    Layout: LayoutWeb,
    component: Error404,
  },
];

const routes = [...routesAdmin, ...routesWeb];

export default routes;
