import React from 'react';
import { Col, Row, Button } from 'antd';
import { PhoneFilled } from '@ant-design/icons';
import WhatsApp from '../../img/whatsapp.svg';

function CallUs (props) {
    return(
        <div className="block textBlock" style={{padding: '30px', backgroundColor: props.backColor}}>
           <div className="container-fluid">
               <div className="CallTitleHolder" style={{padding: '0', margin: '0' }}>
                   <h3 style={{color: props.colorText}}>Llámanos para obtener más información acerca de <strong style={{ color: props.secondColor }}>Inscripciones</strong> o <strong style={{ color: props.secondColor }}>Planes de Financiamiento</strong></h3>
                </div>
                <Row gutter={[8, 16]} style={{ justifyContent: 'center', alignItems: 'center'}}>
                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} align='middle' style={{ justifyContent: 'center', alignItems: 'center'}}>
                <div className='PhoneHolder'>
                <PhoneFilled className='PhoneIcon2' style={{color: props.secondColor}} />
                    <p><strong style={{ color: props.colorText}}>618-811-58-73</strong></p>
                </div>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} align='middle' style={{ justifyContent: 'center', alignItems: 'center'}}>
                    <div className='WhatsAppBtnHolder'>
                         <img className='WhatsAppIcon2' src={WhatsApp} width='55px' />
                            <a href='https://api.whatsapp.com/send?phone=526182304595' target={'_blank'}>
                            <Button type='primary' className='WhatsAppBtn' style={{backgroundColor: props.btnSecondColor, color: props.btnTextColor}}>618-230-45-95</Button>
                            </a>
                        </div>
                </Col>
            </Row>
            </div>
        </div>
    );
}

export default CallUs;