import React from 'react';
import { Card, Row, Col, Image } from 'antd';
import Banner from '../components/Banner/Banner.js';
import Testimonials from '../components/Testimonials/Testimonials.js';
import Maps from '../components/Maps/Maps.js';
import About from '../components/About/AboutIGFE.js';

import IGFE from '../img/IGFE.jpg';
import Oficinas from '../img/IGFE-letrero.png';
import Student from '../img/preparatoria.jpg';

import DrMelchor from '../img/Directivos/DrMelchor.png';
import MspYolanda from '../img/Directivos/MspYolanda.png';
import DraNadia from '../img/Directivos/DraNadia.png';
import DrValentin from '../img/Directivos/DrValentin.png';
import LicPaulette from '../img/Directivos/LicPaulette.png';
import IngMarli from '../img/Directivos/IngMarli.png';
import IngIan from '../img/Directivos/IngIan.png';
import IngEvelyn from '../img/Directivos/IngEvelyn.png';

import DrMelchorV from '../img/Consultivos/DrMelchor.png';
import MBAAlfonso from '../img/Consultivos/MbaAlfonso.png';
import DrRene from '../img/Consultivos/DrRene.png';
import DrArturo from '../img/Consultivos/DrArturo.png';
import DrTomas from '../img/Consultivos/DrTomas.png';

const { Meta } = Card;

const Directivos = [
    {
        key: '1',
        image: DrMelchor,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
                    color: '#5f5c5d', textTransform: 'capitalize'}}>Dr. J. Melchor Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Director General</p>,
    },
    {
        key: '2',
        image: MspYolanda,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>M.S.P. Yolanda Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Directora de Educación</p>,
    },
    {
        key: '3',
        image: DraNadia,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>Dra. Nadia Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Dir. de Programas Educativos e Investigación</p>,
    },
    {
        key: '4',
        image: DrValentin,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>Dr. J. Valentin Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Dir. de Programas Educativos e Investigación Foráneos</p>,
    },
    {
        key: '5',
        image: LicPaulette,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>L.E.N. Paulette Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Dir. de Marketing y Relaciones Públicas</p>,
    },
    {
        key: '6',
        image: IngMarli,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>I.Q. Marli N. Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Dir. de Gestión</p>,
    },
]

const Directivos2 = [
    {
        key: '7',
        image: IngIan,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
                    color: '#5f5c5d', textTransform: 'capitalize'}}>I.T. Ian Badillo Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Director de Sistemas Digitales</p>,
    },
    {
        key: '8',
        image: IngEvelyn,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>I.T.A. Evelyn V. Avalos</h6>,
        Position: <p style={{fontSize: '1rem'}}>Coordinadora de Control Escolar</p>,
    },
]

const Consultivos = [
    {
        key: '1',
        image: DrMelchorV,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
                    color: '#5f5c5d', textTransform: 'capitalize'}}>Dr. J. Melchor Velázquez</h6>,
        Position: <p style={{fontSize: '1rem'}}>Catedrático UJED - Durango, Méx.</p>,
    },
    {
        key: '2',
        image: MBAAlfonso,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>M.B.A. Alfonso Gastañaduy</h6>,
        Position: <p style={{fontSize: '1rem'}}>Catedrático ESAN - Lima, Perú.</p>,
    },
    {
        key: '3',
        image: DrRene,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>Dr. René Berardi</h6>,
        Position: <p style={{fontSize: '1rem'}}>Catedrático UFPR - Curitiba, Brasil.</p>,
    },
]

const Consultivos2 = [
    {
        key: '3',
        image: DrArturo,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
                    color: '#5f5c5d', textTransform: 'capitalize'}}>Dr. Arturo Reveles</h6>,
        Position: <p style={{fontSize: '1rem'}}>Catedrático UJED - Durango, Méx.</p>,
    },
    {
        key: '4',
        image: DrTomas,
        title: <h6 style={{fontWeight: '900', fontSize: '1rem', 
        color: '#5f5c5d', textTransform: 'capitalize'}}>Dr. Tomás Díaz</h6>,
        Position: <p style={{fontSize: '1rem'}}>Catedrático UJED - Durango, Méx.</p>,
    },
]

const Paragraph = <p>Somos un <strong>Instituto 100% en línea</strong>, certificados por la <strong>SEP</strong>, 
    para aquellas personas que aspiran prepararse para desarrollar sus talentos 
    en el <strong>ámbito académico y laboral</strong>. Nuestras oficinas ubicadas en <strong>Durango, México</strong>, 
    están diseñadas para crear un espacio en donde podemos desarrollar y aplicar las 
    mejores herramientas para el <strong>sistema de aprendizaje</strong> que ofrecemos.
    <br/>
    <br/>
    Si estas buscando prepararte mediante un sistema educativo que te de <strong>flexibilidad 
    de tiempo</strong>, en donde puedas acceder a tus clases en cualquier hora del día, desde 
    cualquier lugar, y sobre todo con <strong>maestros capacitados</strong> en la <strong>modalidad online</strong>, el 
    <strong> Instituto Global de Formación y Educación</strong>, ¡es tu camino!.</p>

const PrepaInfo = <p>Inicia tu <strong>formación profesional</strong> con nuestra preparatoria. 
    Con <strong>duración de 2 años</strong>, estudia en una modalidad completamente flexible que te 
    permitirá tomar clase <strong>a la hora que te sea posible, estés donde estés</strong>.
    <br/>
    Si estas buscando desarrollar un excelente perfil, con <strong>certificado valido ante 
    la SEP</strong> y sobre todo con <strong>maestros capacitados</strong>, <br/>¡Éste es tu camino!.</p>

export default function AboutUs(){
    return (
        <>
        <Banner bannerImg={IGFE} section={'Instituto Global de Formación y Educación'} id={'IGFE'}/>
        <div className="whiteBlock grayTextBlock" style={{paddingBottom: '0'}}>
           <div className="container-fluid">
               <div className="titleHolder" style={{paddingBottom: '0', marginBottom: '0'}}>
                   <h1 style={{margin: '0 0'}}>Un Instituto diseñado para ti</h1>
                   <h2 style={{margin: '0 0'}}>Donde la educación No Tiene Fronteras</h2>
                </div>
            </div>
        </div>
        <About idComponent={'Nosotros'} aboutImg={Oficinas}
               aboutText={Paragraph}
               btnLink={'/contacto'}
               btnText={'¡Contáctar!'} />
        <Testimonials />
        <div className="block textBlock" style={{padding: '80px 25px 10px 25px'}}>
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1>Nuestras Oficinas</h1>
                </div>
            </div>
        </div>
        <div id='map'>
        <Maps />
        </div>
        <div id='Nosotros' className="whiteBlock grayTextBlock" style={{background: '#f9f9f9' }}>
           <div className="container-fluid">
               <div className="titleHolder" style={{paddingBottom: '0', marginBottom: '0'}}>
                   <h1>Ellos están detras del Instituto</h1>
                   <h2 style={{marginBottom: '40px', color: '#206cb4'}}>Consejo Directivo</h2>
                </div>
                <Row gutter={[32, 32]} style={{marginBottom: '40px'}}>
                {Directivos.map((Directivo, index) => {
                return(
                    <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle' key={index}>
                         <Card className="consejoCard" hoverable style={{width: 280, background: 'radial-gradient(#EDEDED, #F9F9F9)' }}
                            cover={<img alt={Directivo.title} src={Directivo.image} />} >
                            <Meta title={Directivo.title} description={Directivo.Position} />
                        </Card>
                    </Col>
                    )
                })}
                </Row>
                <Row gutter={[16, 32]}>
                {Directivos2.map((Director2, index) => {
                return(
                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle' key={index}>
                         <Card className="consejoCard" hoverable style={{width: 280, background: 'radial-gradient(#EDEDED, #F9F9F9)' }}
                            cover={<img alt={Director2.title} src={Director2.image} />} >
                            <Meta title={Director2.title} description={Director2.Position} />
                        </Card>
                    </Col>
                    )
                })}
                </Row>
                <div className="titleHolder" style={{paddingBottom: '0', marginBottom: '0'}}>
                   <h2 style={{margin: '40px 0', color: '#206cb4'}}>Consejo Consultivo</h2>
                </div>
                <Row gutter={[48, 24]} style={{marginBottom: '40px'}}>
                {Consultivos.map((Consultivo, index) => {
                return(
                    <Col lg={{ span: 8 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle' key={index}>
                        <Image
                            width={150}
                            src={Consultivo.image}
                            preview={false}
                            style={{borderRadius: '100%', marginBottom: '20px', background: 'radial-gradient(#F9F9F9, #EDEDED)' }}
                        />
                        <div>{Consultivo.title}</div>
                        <div>{Consultivo.Position}</div>
                    </Col>
                    )
                })}
                </Row>
                <Row gutter={[8, 24]}>
                {Consultivos2.map((Consultivo2, index) => {
                return(
                    <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle' key={index}>
                        <Image
                            width={150}
                            src={Consultivo2.image}
                            preview={false}
                            style={{borderRadius: '100%', marginBottom: '20px', background: 'radial-gradient(#F9F9F9, #EDEDED)'}}
                        />
                        <div>{Consultivo2.title}</div>
                        <div>{Consultivo2.Position}</div>
                    </Col>
                    )
                })}
                </Row>
            </div>
        </div>
        <div className="whiteBlock grayTextBlock" style={{paddingBottom: '0'}}>
           <div className="container-fluid">
               <div className="titleHolder" style={{paddingBottom: '0', marginBottom: '0'}}>
                   <h1 id="costosTitleSection" style={{margin: '0 0'}}>Nuestra Preparatoria</h1>
                </div>
            </div>
        </div>
        <About aboutImg={Student}
               aboutText={PrepaInfo}
               hideCard={'none'}
               btnLink={'/preparatoria'}
               btnText={'Conocer Más'} />
        </>
    );
}