import React, { useState, useEffect } from "react";
import { Switch, List, Avatar, Button,notification } from "antd";
import NoAvatar from "../../../../img/no-avatar.png";
import Modal from "../../../Modal/Modal";
import EditUserForm from "../EditUserForm";
import { getAvatarApi, activateUserApi } from "../../../../api/user";
import {getAccessTokenApi } from "../../../../api/auth";


import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import "./ListUsers.less";

export default function ListUsers(props) {
  const { usersActive, usersInactive,setReloadUsers } = props;
  const [viewUserActive, setviewUserActive] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setmodalTitle] = useState("modal");
  const [modalContent, setmodalContent] = useState("hola");

  return (
    <div className="list-users">
      <div className="list-users__switch">
        <Switch
          defaultChecked
          onChange={() => setviewUserActive(!viewUserActive)}
        />
        <span>
          {viewUserActive ? "usuarios activos" : "usuarios Inactivos"}
        </span>
      </div>
      {viewUserActive ? (
        <UsersActive
          usersArray={usersActive}
          setIsVisibleModal={setIsVisibleModal}
          setmodalTitle={setmodalTitle}
          setmodalContent={setmodalContent}
          setReloadUsers={setReloadUsers}
        />
      ) : (
        <UsersInactive usersArray={usersInactive} setReloadUsers={setReloadUsers}/>
      )}

      <Modal
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        title={modalTitle}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function UsersActive(props) {
  const { usersArray, setIsVisibleModal, setmodalTitle, setmodalContent ,setReloadUsers} =
    props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setmodalTitle(
      `Editar: ${user.name ? user.name : "..."} ${
        user.lastname ? user.lastname : "..."
      }`
    );
    setmodalContent(<EditUserForm user={user} setIsVisibleModal={setIsVisibleModal}  setReloadUsers={setReloadUsers}/>);
  };

  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersArray}
      renderItem={(user) => <UserActive  user={user} editUser={editUser} setReloadUsers={setReloadUsers} />}
    ></List>
  );
}

function UsersInactive(props) {
  const { usersArray,setReloadUsers} = props;
  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersArray}
      renderItem={user => <UserInactive user={user} setReloadUsers={setReloadUsers}/> }
    ></List>
  );
}
function UserActive(props) {
  const { user, editUser ,setReloadUsers} = props;
  const [avatar, SetAvatar] = useState(null);
  // console.log(user);
  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        SetAvatar(response);
      });
    } else {
      SetAvatar(null);
    }
  }, [user]);

  const desactivateUser =()=>{
    const accestoken = getAccessTokenApi();
    
    activateUserApi(accestoken,user._id,false)
    .then(response => {
      notification["success"]({message:response.message});
      setReloadUsers(true); 
    }).catch(err=>{
      notification["error"]({message:err});
      
    });

  }

  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={() => editUser(user)}>
          <EditOutlined />
        </Button>,
        <Button type="danger" onClick={desactivateUser}>
          <StopOutlined />
        </Button>,
        <Button type="danger" onClick={() => console.log("Eliminar")}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
        title={`${user.name ? user.name : "..."} 
                      ${user.lastname ? user.lastname : "..."}
                  `}
        description={user.email}
      />
    </List.Item>
  );
}

function UserInactive (props){
  const {user,setReloadUsers} = props;
  const [avatar, SetAvatar] = useState(null);
  // console.log(user);
  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        SetAvatar(response);
      });
    } else {
      SetAvatar(null);
    }
  }, [user]);

  const activateUser =()=>{
    const accestoken = getAccessTokenApi();
    
    activateUserApi(accestoken,user._id,true)
    .then(response => {
      notification["success"]({message:response.message});
      setReloadUsers(true); 
    }).catch(err=>{
      notification["error"]({message:err});
      
    });

  }


  return (
   
    <List.Item
    actions={[
      <Button type="primary" onClick={activateUser}>
        <CheckOutlined />
      </Button>,
      <Button type="danger" onClick={() => console.log("Eliminar")}>
        <DeleteOutlined />
      </Button>,
    ]}
  >
    <List.Item.Meta
      avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
      title={`${user.name ? user.name : "..."} 
                          ${user.lastname ? user.lastname : "..."}
                      `}
      description={user.email}
    />
  </List.Item>
  );
}