import React, { useState } from "react";
import { Input, Checkbox, Button, Form,notification } from "antd";
import { sendQuestionApi } from "../../api/emails";


const { TextArea } = Input;

const QuestionForm = () => {
   const [inputs, setInputs] = useState({
    fullName: "",
    email: "",
    estado: "",
    question: "",
    privacyPolicy: false
  });
  
  const changeForm = e => {
    if (e.target.name === "privacyPolicy") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value
      });
    }
  };

  const sendEmail = async e => {
    const emailVal = inputs.email;
    const fullnameval = inputs.fullName;
    const estadoval = inputs.estado;
    const questionval = inputs.question;
    const privacyPolicyval = inputs.privacyPolicy;


    if (!emailVal || !fullnameval || !estadoval || !questionval) {
      notification["error"]({
        message: "Todos los campos son obligatorios"
      });
    } else {
      if (!privacyPolicyval){
        notification["error"]({
          message: "Favor de aceptar el acuerdo de privacidad"
        });
      }else{

        const result = await sendQuestionApi(inputs);
        if (result.code===500) {
          notification["error"]({
            message: result.message
          });
        } else {
          notification["success"]({
            message: result.message
          });
          resetForm();
      }

    }
  }
}

  const resetForm = () => {
    setInputs({
      fullName: "",
      email: "",
      estado: "",
      cursoInteres: "",
      privacyPolicy: false
    }); 
  }


  return (
      <Form className='Forms' onChange={changeForm} onFinish={sendEmail}>
        <Input type="email" name="email" value={inputs.email} placeholder="Escribe tu Email" />
      <Input type="name" name="fullName" value= {inputs.fullName} placeholder="Tu Nombre Completo" />
      <Input name="estado" value= {inputs.estado} placeholder="Estado donde vives" />
        <TextArea
        value={inputs.question}
        name="question"
        placeholder="¡Escríbe aquí tus dudas!"
        autoSize={{
          minRows: 3,
          maxRows: 5,
        }}
        style={{maxWidth: '500px'}}
      />
      <div className='btnCheck'>
      <Checkbox name="privacyPolicy"  checked={inputs.privacyPolicy} style={{color: "#fff"}}>
          He leído y estoy de acuerdo con el <a href="https://drive.google.com/file/d/1UiooxISlKgqNFhWjKw8GCVsZ7eer-F27/view?usp=sharing" 
                            target={"_blank"} rel="noreferrer">Aviso de Privacidad.</a>
        </Checkbox>
        <Button className="btnBecas" type='primary' style={{backgroundColor: '#F7B41F'}}  htmlType="submit">Enviar</Button>
      </div>
      </Form>
  );
};

export default QuestionForm;