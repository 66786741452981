import React from "react";
import { Pagination as PaginationAntd } from "antd";
import { useNavigate } from 'react-router-dom';


import "./Pagination.less";

export default function Pagination(props) {
  const { posts, location} = props;
  const history = useNavigate();
  const currentPage = parseInt(posts.page);

  const onChangePage = newPage => {
    history(`${location.pathname}?page=${newPage}&limit=${posts.limit}`);
  };

  return (
    <PaginationAntd
      defaultCurrent={currentPage}
      total={posts.total}
      pageSize={posts.limit}
      onChange={newPage => onChangePage(newPage)}
      className="pagination"
    />
  );
}
