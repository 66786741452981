import React, { Children } from "react";
import { Layout } from 'antd';
import Navbar from '../components/Navbar/Navbar';
import '../App.less';
import AppFooter from '../components/Footer/Footer';

export default function LayoutWeb(props) {
  const { children } = props;
  const { Header, Content, Footer } = Layout;

  return (
    <Layout className='mainLayout'>
      <Header style={{ position: 'fixed', width:'100%'}}>
        <Navbar />
      </Header>
      <Content>
        {children}
      </Content>
      <Footer>
        <AppFooter />
      </Footer>
  </Layout>
    );
}
