import { Col, Row } from 'antd';
import QuestionCard from './QuestionCard';

import Student from '../../img/studentFAQ.jpg';
import Father from '../../img/fatherFAQ.jpg';

const items = [
    {
        key: '1',
        image: Father,
        title: <h2>Información para Padres</h2>,
        route: '/informacion-padres',
    },
    {
        key: '2',
        image: Student,
        title: <h2>Información para Estudiantes</h2>,
        route: '/informacion-estudiantes',
    },
]

function Questions() {
    return(
        <div id="FAQ" className="whiteBlock grayTextBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1 id='FAQTitle'>Muchas respuestas a muchas dudas</h1>
                   <h2 id='FAQSubTitle'>Visíta nuestro apartado de preguntas frecuentes</h2>
               </div>
               <Row gutter={[8, 8]}>
               {items.map((item, index) => {
                   return(
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle' key={index}>
                    <QuestionCard route={item.route} ask_image={item.image}
                            ask_title={item.title} />
                </Col>
                   )
                })}
            </Row>
            </div>
        </div>
    );
}

export default Questions;