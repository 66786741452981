import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ReadFilled } from '@ant-design/icons';

import Teacher from '../../img/teacher.png';
import Tutor from '../../img/tutor.png';
import Library from '../../img/library.png';
import Platform from '../../img/platform.png';

const items = [
    {
        key: '1',
        icon: <img src={Platform} className="processIcon" alt='Plataforma Digital 24/7' width={'100px'} />,
        content: <p>Aula Virtual <strong>24/7</strong></p>,
    },
    {
        key: '2',
        icon: <img src={Tutor} className="processIcon" alt='Acompañamiento' width={'100px'} />,
        content: <p><strong>Asesoria</strong> Academica</p>,
    },
    {
        key: '3',
        icon: <img src={Teacher} className="processIcon" alt='Maestros Capacitados' width={'100px'} />,
        content: <p>Maestros <strong>Capacitados</strong></p>,
    },
    {
        key: '4',
        icon: <img src={Library} className="processIcon"alt='¡Biblioteca!' width={'100px'} />,
        content: <p><strong>Biblioteca</strong> Digital</p>,
    },
]

function AppProcess() {
    return(
               <div className="block processBlock">
                   <div className="container-fluid">
                       <div className="titleHolder">
                           <h2>¿Cómo se estudia en el Instituto?</h2>
                       </div>
                       <Row className='processRow' gutter={[16, 16]}>
                           {items.map((item, index) => {
                               return(
                                <Col className='processCol' 
                                lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}
                                 align='middle' key={index}>
                                    <div className='processCard'>
                                        <div>
                                    {item.icon}
                                    </div>
                                    {item.content}
                                    </div>
                                </Col>
                               );
                           })}
                        </Row>
                        <div className='processBtnHolder'>
                        <Link to='/oferta-educativa'>
                            <Button type='primary' className='processBtn'>Ver cursos <ReadFilled className='Phone' style={{fontSize: '1.1rem', lineHeight: '0'}}/></Button>
                            </Link>
                        </div>
                    </div>
                </div>
    );
}

export default AppProcess;