import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

const { Panel } = Collapse;

function GeneralFAQ () {
    return (
        <div className='block textBlock' style={{ backgroundColor: '#F78820'}}>
            <div className='container-fluid' style={{ backgroundColor: '#F78820'}}>
                <div className='titleHolder'>
                    <h1 id="costosTitleSection" >Preguntas Frecuentes</h1>
                </div>
                <div className='FAQ-Container'>
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="site-collapse-custom-collapse"
                    style={{borderRadius:'5px', backgroundColor: '#F78820', paddingBottom: '10px'}}>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Cómo me puedo <strong>Inscribir?</strong></h3>} className="site-collapse-custom-panel">
                            <p>El método de inscripción es muy sencillo, únicamente 
                            ponte en <strong>contácto con nuestro departamento de control escolar </strong> 
                             a través del Correo Electrónico: <strong>servicios.escolares@igfe.mx</strong>, ellos te guiaran durante todo el proceso.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Son <strong>estudios validos ante la SEP?</strong></h3>} className="site-collapse-custom-panel">
                            <p>Contamos con el <strong>Registro de Validez Oficial de Estudios</strong> de la Secretaria de Educación Publica.
                            Nuestro número de RVOE: 1229/2021. ¡Por lo que tus estudios serán validos de <strong>manera oficial en todo México!</strong>.</p>
                            </Panel>
                            <Panel header={<h3 style={{color: '#5f5c5d', margin: '0 0'}}>¿Puedo <strong>revalidar materias?</strong></h3>} className="site-collapse-custom-panel">
                            <p>Para más información acerca de la revalidación de materias puedes comunicarte directamente a nuestro 
                                departamento de Control Escolar: <strong>servicios.escolares@igfe.mx</strong>.
                            </p>
                            </Panel>
                </Collapse>
                </div>
            </div>
        </div>
    );
};

export default GeneralFAQ;