import React from 'react';
import { Row, Col, Image } from 'antd';
import Periodos from './Periodos';

import rvoe from '../../img/rvoe-gray.png';
import valid from '../../img/valid-gray.png';
import './PlanEstudios.less';

const items = [
    {
        key: '1',
        Cuatrimestre: <h1>Primer<br/>Cuatrimestre</h1>,
        Subjects:<p>Matemáticas I<br/>
                    Inglés I<br/>
                    Química I<br/>
                    Ética I<br/>
                    Lectura y Redacción I<br/>
                    Informática I<br/>
                    Metodología de la Investigación<br/>
                    Herramientas para el Aprendizaje</p>
    },
    {
        key: '2',
        Cuatrimestre: <h1>Segundo<br/>Cuatrimestre</h1>,
        Subjects:<p>Matemáticas II<br/>
                    Inglés II<br/>
                    Química II<br/>
                    Ética II<br/>
                    Lectura y Redacción II<br/>
                    Informática II<br/>
                    Introducción a las Ciencias Sociales<br/>
                    Nutrición</p>
    },
    {
        key: '3',
        Cuatrimestre: <h1>Tercer<br/>Cuatrimestre</h1>,
        Subjects:<p>Matemáticas III<br/>
                    Inglés III<br/>
                    Biología I<br/>
                    Física I<br/>
                    Historia de México I<br/>
                    Literatura I<br/>
                    Emprendedurismo I<br/>
                    Inteligencia Emocional</p>
    },
    {
        key: '4',
        Cuatrimestre: <h1>Cuarto<br/>Cuatrimestre</h1>,
        Subjects:<p>Matemáticas IV<br/>
                    Inglés IV<br/>
                    Biología II<br/>
                    Física II<br/>
                    Historia de México II<br/>
                    Literatura II<br/>
                    Emprendedurismo II<br/>
                    Vida Saludable y Deporte</p>
    },
    {
        key: '5',
        Cuatrimestre: <h1>Quinto<br/>Cuatrimestre</h1>,
        Subjects:<p>Cálculo Diferencial<br/>
                    Inglés V<br/>
                    Geografía<br/>
                    Administración I<br/>
                    Ciencias de la Salud I<br/>
                    Estructura Socioeconómica de México<br/>
                    Ciencias de la Comunicación I<br/>
                    Emprendedurismo III<br/>
                    Elección Profesional</p>
    },
    {
        key: '6',
        Cuatrimestre: <h1>Sexto<br/>Cuatrimestre</h1>,
        Subjects:<p>Cálculo Integral<br/>
                    Inglés VI<br/>
                    Filosofía<br/>
                    Administración II<br/>
                    Ciencias de la Salud II<br/>
                    Historia Universal Contemporánea<br/>
                    Ciencias de la Comunicación II<br/>
                    Emprendedurismo IV<br/>
                    Proyecto de Vida</p>
    },
]

function PlanEstudios(props) {
    return(
        <div className="whiteBlock grayTextBlock">
        <div className="container-fluid">
            <div className="titleHolder">
                <h1 id="EstudiosTitle">¿Qué aprenderás?</h1>
                <h2>Lee con detenimiento los conocimientos que adquirirás</h2>
            </div>
            <Row justify='center'>
            {items.map((item, index) => {
                        return (
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} key={index} align='middle'>
                            <Periodos periodoTitle={item.Cuatrimestre}
                                      materias={item.Subjects} />
                        </Col>
                        );
                    })}
            </Row>
            <div style={{marginTop: '40px'}}>
            <div className="rvoe"  style={{borderTop: '1px solid #5f5c5d', borderBottom: '1px solid #5f5c5d'}} >
                   <p style={{color: '#5f5c5d'}}>Programa educativo reconocido por la <strong>SEP</strong>, máxima autoridad educativa en México, con Registro de Validez Oficial de Estudios. <strong>No: 1229/2021</strong></p>
               </div>
               <div className="sepIcons">
                   <div className='rv'>
                       <Image src={rvoe} width={'110px'} preview={false}/>
                    </div>
                    <div className='sep'>
                       <Image src={valid} width={'100px'} preview={false} />
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default PlanEstudios;