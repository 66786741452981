import React from "react";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import PostsListWeb from "../components/Blog/PostsListWeb";
import PostInfo from "../components/Blog/PostInfo";
import {withRouter} from "../hooks/withRouter"

function Blog(props) {
  const { location, history } = props.router;
  // const { url } = useParams();
  const url = location.pathname.split("/")
  
  return (
    <>
        {url[2] ? (
          <PostInfo url={url[2]} />
        ) : (
          <PostsListWeb location={location} history={history} />
        )}
      
    </>
  );
}
export default withRouter(Blog);
