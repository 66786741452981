import React from 'react';

import './Banner.less';

export default function Banner(props) {
    return (
        <div id="banner" className="block bannerBlock" style={{backgroundImage: `url(${props.bannerImg})`}}>
                <div className="container-fluid">
                    <div className="titleHolder" style={{ padding: '0', margin: '0'}}>
                        <h2>{props.section}</h2>
                    </div>
                </div>
        </div>
    );
}