import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import routes from "./config/routes";
import AuthProvider from "./providers/AuthProvider";
import CookiesBanner from "./components/Cookies/Cookies";

import "./App.less";

function App() {
  return (
    <AuthProvider>
      <CookiesBanner />
      <Router>
      <ScrollToTop />
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <route.Layout>
                  <route.component />
                </route.Layout>
              }
            />
          ))}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
