import rvoe from '../../img/rvoe.png';
import valid from '../../img/valid.png';
import { Row, Col, Image } from 'antd';
import PhotoCard from '../../components/photoCard/PhotoCard.js';

import EnLinea from '../../img/enlinea-01.png';
import Certificado from '../../img/enlinea-02.png';
import NuncaTarde from '../../img/enlinea-03.png';


const items = [
    {
        key: '1',
        image: EnLinea,
        title: <h2>100% En Línea</h2>,
        content: <p><strong>Estudia sin complicaciones</strong>, no importa si trabajas al mismo tiempo, 
        nuestra modalidad te permitirá estudiar, <strong>desde cualquier lugar, a cualquier hora</strong>.</p>,
    },
    {
        key: '2',
        image: Certificado,
        title: <h2>Certificado Oficial</h2>,
        content: <p>Nuestros <strong>programas educativos</strong> están reconocidos por la máxima autoridad 
        educativa mexicana <strong>(SEP)</strong>, por lo que al concluir tus estudios recibirás tu <strong>certificado oficial</strong>.</p>,
    },
    {
        key: '3',
        image: NuncaTarde,
        title: <h2>Nunca es Tarde</h2>,
        content: <p>Todos nuestros <strong>planes educativos</strong> los hemos diseñado tanto para jóvenes que comenzaran 
        su etapa <strong>preparatoria</strong>, como para personas mayores que buscan superarse día con día.</p>,
    },
]

function AppProposal() {
    return(
       <div id="Oferta" className="block textBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1>Estudia a tu Ritmo</h1>
                   <h2>Desde cualquier lugar</h2>
               </div>
               <div className="rvoe">
                   <p>Programa educativo reconocido por la <strong>SEP</strong>, máxima autoridad educativa en México, con Registro de Validez Oficial de Estudios. <strong>No: 1229/2021</strong></p>
               </div>
               <div className="sepIcons">
                   <div className='rv'>
                       <Image src={rvoe} width='110px' preview={false} />
                    </div>
                    <div className='sep'>
                       <Image src={valid} width='100px' preview={false} />
                    </div>
                </div>
                <div className='photos'>
                <Row gutter={[16, 16]}>
                    {items.map((item, index) => {
                        return (
                        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} align='middle' key={index}>
                            <PhotoCard card_image={item.image}
                                    card_title={item.title} 
                                    card_text={item.content}/>
                            </Col>
                        );
                    })}
                </Row>
                </div>
           </div>
       </div>
    );
}

export default AppProposal;