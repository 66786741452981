import React from 'react';

import './PlanEstudios.less';

function Periodos(props) {
    return(
        <div className="periodoBlock">
            <div className="periodoTitle">
                <div>{props.periodoTitle}</div>
            </div>
            <div className='materias'>
                <div>{props.materias}</div>
            </div>
        </div>
    );
};

export default Periodos;