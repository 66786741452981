import React from 'react'
import InfoForm from '../components/Form/InfoForm.js';
import Banner from '../components/Banner/Banner.js';
import CallUs from '../components/CallUs/CallUs.js';

import Contacto from '../img/contact.jpg';
import Maps from '../components/Maps/Maps.js';

export default function Contact(){
    return (
        <>
        <Banner bannerImg={Contacto} section={'Inscríbete Hoy'}/>
        <div className="whiteBlock grayTextBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1 id="costosTitleSection" >Contacta a Control Escolar</h1>
                   <h2>Escríbe tus datos y con gusto te brindarán más detalles</h2>
               </div>
                 <InfoForm />
           </div>
        </div>
        <div className="block textBlock" style={{padding: '80px 25px 10px 25px'}}>
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1>Nuestras Oficinas</h1>
                </div>
            </div>
        </div>
        <div id='map'>
        <Maps />
        </div>
        <CallUs backColor={'#f7b41f'} btnSecondColor={'#206cb4'} btnTextColor={'#FFF'}/>
        </>
    );
}