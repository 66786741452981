import React from 'react'
import Banner from '../components/Banner/Banner';
import CallUs from '../components/CallUs/CallUs';
import QuestionForm from '../components/Form/QuestionForm';
import About from '../components/About/AboutIGFE';
import PlanEstudios from '../components/PlanEstudios/PlanEstudios';
import Costos from '../components/Costos/Costos';
import GeneralFAQ from '../components/FAQ/GeneralFAQ';

import PrepaIGFE from '../img/prepa.jpg';
import PreparatoriaImg from '../img/preparatoria.jpg';

const Paragraph = <p>Da igual si hace tiempo que terminaste tu secundaria, 
                    o la acabas de concluir, si estás trabajando, o solamente estudiando. 
                    No importa si eres un adulto mayor o alguien menor de edad. <strong>Nuestra 
                    preparatoria</strong> esta diseñada para todas aquellas personas que siempre 
                    están en el reto de <strong>ir más allá</strong>, para tener mejores <strong>oportunidades 
                    de vida</strong> o ampliar sus <strong>conocimientos</strong>.<br/>
                    <br/>
                    Al concluir <strong>aprobatoriamente</strong> tus estudios, tendrás <strong>una mayor 
                    oportunidad</strong> para incursionar en el mundo laboral y/o en tus <strong>estudios 
                    profesionales</strong>.</p>

export default function Preparatoria(){
    return (
        <>
        <Banner bannerImg={PrepaIGFE} section={'Preparatoria'}/>
        <div className="whiteBlock grayTextBlock" style={{ paddingBottom: '0' }}>
           <div className="container-fluid">
               <div id='infoSec' className="titleHolder">
                   <h2 id='subTitleSection'>Aquí es donde crecerás</h2>
                   <h1 id='titleSection'>Profesionalmente</h1>
               </div>
            </div>
        </div>
        <About aboutImg={PreparatoriaImg}
               aboutSubTitle={'Preparación para el futuro'}
               aboutText={Paragraph}
               btnLink={'/contacto'}
               btnText={'¡Me Interesa!'}
               btnColor={'#f7b41f'}
               btnTextColor={'#5f5c5d'} />
        <PlanEstudios />
        <Costos />
        <CallUs backColor={'#f7b41f'} btnSecondColor={'#206cb4'} btnTextColor={'#FFF'}/>
        <GeneralFAQ />
        <div className="block textBlock">
           <div className="container-fluid">
               <div className="titleHolder">
                   <h1>¿Aún tienes dudas?</h1>
                   <h2>¡Escríbenos!</h2>
               </div>
            <QuestionForm />
           </div>
        </div>
        
        </>
    );
}