import React from "react";
import { Link } from "react-router-dom";
import './Questions.less';

const QuestionCard = (props) => {
    return (
        <Link to={props.route}>
            <div className='question' style={{backgroundImage: `url(${props.ask_image})`}}>
                <div className='questionContent'>
                <div className='questionTitle' id='ask'>{props.ask_title}</div>
            </div>
        </div>
        </Link>
    )
}

export default QuestionCard;