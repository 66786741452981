import { basePath, apiVersion } from "./config";

export function sendEmailApi(data) {
    const url = `${basePath}/${apiVersion}/send-email`;
    
    const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      };

    return fetch(url,params)
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      })
      .catch(err => {
        return err;
      });
  }

  export function sendQuestionApi(data) {
    const url = `${basePath}/${apiVersion}/send-question`;
    
    const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      };

    return fetch(url,params)
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      })
      .catch(err => {
        return err;
      });
  }


  export function subscribeMailChimpApi(data) {
    const url = `${basePath}/${apiVersion}/subscribe`;
    
    const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      };

    return fetch(url,params)
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      })
      .catch(err => {
        return err;
      });
  }