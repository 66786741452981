import React, { useState } from "react";
import { Layout } from "antd";
import { Navigate } from "react-router-dom";
import MenuTop from "../components/Admin/MenuTop/";
import MenuSider from "../components/Admin/MenuSider";
import UseAuth from "../hooks/useAuth";
import "./LayoutAdmin.less";

export default function LayoutAdmin(props) {
  const { children } = props;
  const { Header, Sider, Content } = Layout;

  const [menuCollapsed, setMenuCollapsed] = useState(false);

  const { user, isLoading } = UseAuth();

  if (!user && !isLoading) {
    return <Navigate to="/admin/login" replace />;
  }

  return (
    <Layout className="layout-admin">
      <Header className="layout-admin_header" style={{ padding: 0 }}>
        <MenuTop
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
        />
      </Header>

      <Layout >
        <Sider className="layout-admin_sider" collapsed={menuCollapsed}>
        <MenuSider
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
        />
        </Sider>
        <Content className="layout-admin_content">{children}</Content>
      </Layout>
    </Layout>
  );
}

// <Layout className="layout-admin" >
//         <MenuSider menuCollapsed={menuCollapsed} setMenuCollapsed ={setMenuCollapsed}/>
//         <Content className="layout-admin_content">{children}</Content>
//         {/* <Footer className="layout-admin_footer">Erick manuel alvarado</Footer> */}
//       </Layout>
