import React from 'react'
import Banner from '../components/Banner/Banner.js';
import Info from '../components/Info/Info.js';
import InfoForm from '../components/Form/InfoForm.js';
import Questions from '../components/Questions/Questions.js';

import Graduados from '../img/graduados.jpg';
import EstudiantePrep from '../img/estudiante-prep.jpg';
import About from '../components/About/AboutIGFE.js';

const Paragraph = <p>Inicia tu <strong>formación profesional</strong> con nuestra preparatoria. Con duración de <strong>2 años</strong>, 
                    estudia en una modalidad completamente flexible que te permitirá tomar clase <strong>a 
                    la hora que te sea posible, estés donde estés</strong>. <br/>
                    Si estas buscando desarrollar un excelente perfil, con <strong>certificado valido</strong> ante 
                    la SEP y sobre todo con <strong>maestros capacitados</strong>, ¡este es tu camino!.</p>

export default function Offer(){
    return (
        <>
        <Banner bannerImg={Graduados} section={'Oferta Educativa'}/>
        <div className="whiteBlock grayTextBlock">
           <div className="container-fluid">
               <div id='infoSec' className="titleHolder">
                   <h2 id='subTitleSection'>Desarrollate Profesionalmente</h2>
                   <h1 id='titleSection'>A Cualquier Hora</h1>
               </div>
            </div>
        </div>
        <About aboutImg={EstudiantePrep}
               aboutTitle2={'Preparatoria'}
               aboutText={Paragraph}
               btnLink={'/preparatoria'}
               btnText={'Conocer Más'} />
        <Questions />
        </>
);
}