import React from "react";
import { useParams } from "react-router-dom";
import PostsListWeb from "../components/Blog/PostsListWeb";
import {withRouter} from "../hooks/withRouter"

function Blog(props) {
  const { location, history } = props.router;
  const { url } = useParams();
  console.log(url);
  return (
    <>
     
          <PostsListWeb location={location} history={history} />
    </>
  );
}
export default withRouter(Blog);
