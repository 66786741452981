import React,{useEffect, useState} from 'react'
import { getUsersActiveApi } from '../../api/user';
import {getAccessTokenApi} from '../../api/auth'; 
import ListUsers from '../../components/Admin/users/ListUsers/ListUsers';

 
export default function Users(){
    const [usersActive,setUsersActive] =useState([]);
    const [reloadUsers,setReloadUsers] =useState(false);
    const [userInactive, setuserInactive] = useState([])
    const token=getAccessTokenApi();

    useEffect(()=>{
        getUsersActiveApi(token,true).then(response =>{
            setUsersActive(response.users)
        });
        getUsersActiveApi(token,false).then(response =>{
            setuserInactive(response.users)
        });
        setReloadUsers(false);
    }, [token,reloadUsers])

    return(
        <div className="users">
            <h2>Lista usuarios</h2>
            <ListUsers
                usersActive={usersActive}
                usersInactive= {userInactive}
                setReloadUsers= {setReloadUsers}
                
            />
        </div>
    )
}